import React, { useState } from "react";

import {
  FormControl,
  FormLabel,
  VStack,
  Input,
  Flex,
  Button,
  Heading,
  useToast,
  Box,
  Badge,
  Textarea,
  color,
  Select,
} from "@chakra-ui/react";
import { colors } from "../../../utils/colors";
import useBlacklistMutation from "../../../hooks/useMutations/useBlacklistMutation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBlacklist from "../../../hooks/useQueries/useBlacklist";

import "../../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import DatePicker from "react-datepicker";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import "./datepicker1.css";
// import Select from "react-select";
import { m } from "framer-motion";

const types = ["Cooldown", "Black List"];
const schema = yup.object().shape({
  fName: yup.string().required("!! First Name is a required field."),
  email: yup.string().email().required("!! Email is a required field."),
  motif: yup.string().min(20).required("!! Reason is a required field."),
  cin: yup.string().optional(),
  type: yup.string().required("!! Type is a required field."),
  // endDate: yup.string().when("type", {
  //   is: types[0],
  //   then: yup.string().required("Suspend Date is required"),
  //   otherwise: yup.string().notRequired(),
  // }),
  phone: yup.string().optional(),
});
// const types = [
//   { value: "suspend", label: "Suspend" },
//   { value: "blacklist", label: "Black List" },
// ];
export default function NewBlacklisted() {
  const [date, setDate] = useState(new Date());
  const [suspenddate, setSuspendDate] = useState(new Date());
  const [type, setType] = useState(new Date());

  const { addBlacklist } = useBlacklistMutation();
  const { refetchBlacklists, blacklists } = useBlacklist();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    console.log("data", data);
    try {
      // date.setHours(date.getHours() + 1);
      // suspenddate.setHours(suspenddate.getHours() + 1);
      await addBlacklist({
        date: date.toISOString().split("T")[0] || Date.now().toString(),
        endDate: suspenddate.toISOString().split("T")[0],
        isBlacklisted: type === types[0] ? false : true,
        cin_passport: data.cin || "",
        fName: data.fName,
        email: data.email,
        phone: data.phone || "",
        motif: data.motif,
      });
      await refetchBlacklists();
      reset();

      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="yellow.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              Blacklisted successfully !!
            </Heading>
          </Box>
        ),
      });
      clearErrors();
    } catch (error) {
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "error",
        render: () => (
          <Box
            bgColor="red.500"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              {error.response?.data?.message ||
                "An error occurred while blacklisting"}
            </Heading>
          </Box>
        ),
      });
      /* if (error.response.data.error.message.includes("Duplicate")) {
        toast({
          position: "bottom-right",
          duration: 5 * 1000,
          status: "success",
          render: () =>
            <Box
              bgColor="red.500"
              borderRadius="5px"
              color="#f5f5f5"
              p="10px"
              textAlign="center"
            >
              <Heading fontSize="xs" textTransform="uppercase">
                Une facture avec ce Numéro existe déjà !!
              </Heading>
            </Box>
        });
      } */
      console.dir(error);
    }
  };

  console.log(blacklists);

  document.title = "TTTBlacklist | New Blacklisted";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack
        align="stretch"
        spacing={10}
        px="50px"
        pb="50px"
        mb="50px"
        mt="100px"
        borderRadius={20}
        className="sidebar_nav"
        maxW="900px"
        mx="auto"
        color="gray.50">
        <Heading size="lg" py="20px" color="black">
          New Blacklisted
        </Heading>

        <Flex align="center" justify="space-between" w="100%">
          <FormControl w="45%">
            <FormLabel color="#000" fontSize={14}>
              Full Name
            </FormLabel>
            <Input
              name="fName"
              {...register("fName", { required: true })}
              type="text"
              placeholder="Enter full name here..."
              borderColor={colors.lightBlue}
              color={colors.lightBlue}
              _focus={{ borderColor: colors.lightBlue }}
              _placeholder={{
                opacity: 0.7,
                color: "#000",
                fontSize: 14,
                fontStyle: "italic",
              }}
            />

            <Badge
              fontWeight={600}
              colorScheme="red"
              fontSize="12px"
              align="left"
              mt="10px">
              {errors.fName?.message}
            </Badge>
          </FormControl>

          <FormControl w="45%">
            <FormLabel color="#000" fontSize={14}>
              Email
            </FormLabel>
            <Input
              name="email"
              {...register("email", { required: true })}
              type="text"
              placeholder="Enter email here..."
              borderColor={colors.lightBlue}
              color={colors.lightBlue}
              _focus={{ borderColor: colors.lightBlue }}
              _placeholder={{
                opacity: 0.7,
                color: "#000",
                fontSize: 14,
                fontStyle: "italic",
              }}
            />
            <Badge
              fontWeight={600}
              colorScheme="red"
              fontSize="12px"
              align="left"
              mt="10px">
              {errors.email?.message}
            </Badge>
          </FormControl>
        </Flex>

        <Flex align="center" justify="space-between">
          <FormControl w="45%">
            <FormLabel color="#000" fontSize={14}>
              Number phone
            </FormLabel>
            <Input
              name="phone"
              {...register("phone", { required: true })}
              type="text"
              placeholder="Enter number phone here..."
              borderColor={colors.lightBlue}
              color={colors.lightBlue}
              _focus={{ borderColor: colors.lightBlue }}
              _placeholder={{
                opacity: 0.7,
                color: "#000",
                fontSize: 14,
                fontStyle: "italic",
              }}
            />
            <Badge
              fontWeight={600}
              colorScheme="red"
              fontSize="12px"
              align="left"
              mt="10px">
              {errors.phone?.message}
            </Badge>
          </FormControl>

          <FormControl w="45%" mb="25px">
            <FormLabel color="#000" fontSize={14}>
              Date
            </FormLabel>
            <DatePicker
              className="datepicker1"
              selected={date}
              onChange={(date) => setDate(date)}
            />
          </FormControl>
        </Flex>
        <Flex align="center" justify="space-between">
          <FormControl w="45%">
            <FormLabel color="#000" fontSize={14}>
              ID or Passport
            </FormLabel>
            <Input
              name="cin"
              {...register("cin", { required: true })}
              type="text"
              placeholder="Enter number ID OR Passport here..."
              borderColor={colors.lightBlue}
              color={colors.lightBlue}
              _focus={{ borderColor: colors.lightBlue }}
              _placeholder={{
                opacity: 0.7,
                color: "#000",
                fontSize: 14,
                fontStyle: "italic",
              }}
            />
            <Badge
              fontWeight={600}
              colorScheme="red"
              fontSize="12px"
              align="left"
              mt="10px">
              {errors.cin?.message}
            </Badge>
          </FormControl>
          <FormControl w="45%">
            <FormLabel color="#000" fontSize={14}>
              Type
            </FormLabel>
            {/* <Select
              color={"black"}
              className="react-select__select"
              classNamePrefix="react-select"
              name="type"
              {...register("type", { required: false })}
              options={types}
              onChange={(val) => setType(val.value)}
              styles={{
                control: (baseStyles, state, isDisabled, isSelected) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "white" : colors.lightBlue,
                  backgroundColor: "transparent",
                  color: "transparent",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: color.lightBlue,
                },
              })}
            /> */}
            <Select
              {...register("type", { required: true })}
              name={"type"}
              borderColor={colors.blue}
              placeholder="Select type..."
              onChange={(e) => setType(e.target.value)}
              className="select_sub">
              <option value={types[0]}>{types[0]}</option>
              <option value={types[1]}>{types[1]}</option>
            </Select>
            <Badge
              fontWeight={600}
              colorScheme="red"
              fontSize="12px"
              align="left"
              mt="10px">
              {errors.type?.message}
            </Badge>
          </FormControl>
        </Flex>
        {type === types[0] && (
          <FormControl w="45%" mb="25px">
            <FormLabel color="#000" fontSize={14}>
              End Date of Cooldown
            </FormLabel>
            <DatePicker
              name="endDate"
              {...register("endDate", { required: false })}
              className="datepicker1"
              selected={suspenddate}
              onChange={(date) => setSuspendDate(date)}
            />
            <Badge
              fontWeight={600}
              colorScheme="red"
              fontSize="12px"
              align="left"
              mt="10px">
              {errors.endDate?.message}
            </Badge>
          </FormControl>
        )}

        <FormControl>
          <FormLabel color="#000" fontSize={14}>
            Reason
          </FormLabel>

          <Textarea
            //  minLength={20}
            name="motif"
            {...register("motif", { required: true })}
            type="text"
            placeholder="Enter reason here..."
            borderColor={colors.lightBlue}
            color={colors.lightBlue}
            _focus={{ borderColor: colors.lightBlue }}
            _placeholder={{
              opacity: 0.7,
              color: "#000",
              fontSize: 14,
              fontStyle: "italic",
            }}
          />

          {/* <Input
            name="motif"
            {...register("motif", { required: true })}
            type="text"
            placeholder="Enter reason here..."
            borderColor={colors.lightBlue}
            color={colors.lightBlue}
            _focus={{ borderColor: colors.lightBlue }}
            _placeholder={{
              opacity: 0.7,
              color: "#ffff",
              fontSize: 14,
              fontStyle: "italic"
            }}
          /> */}
          <Badge
            fontWeight={600}
            colorScheme="red"
            fontSize="12px"
            align="left"
            mt="10px">
            {errors.motif?.message}
          </Badge>
        </FormControl>

        <Flex align="center" justify={`center`} pt="30px">
          <Button
            w="100%"
            borderRadius={15}
            boxShadow="lg"
            color="#ffff"
            fontSize="15px"
            py={25}
            className="app_btn"
            type="submit">
            + Add to blacklist
          </Button>
        </Flex>
      </VStack>
    </form>
  );
}
